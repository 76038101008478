import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useLoggedProfile } from "@shared/hooks/useLoggedProfile";

const menuItems = [
    {
        name: "Skály",
        href: "/skaly",
    },
    {
        name: "Bouldry",
        href: "/bouldry",
    },
    {
        name: "O nás",
        href: "/#o-nas",
    },
];

export function Header() {
    const loggedProfile = useLoggedProfile();

    return (
        <Popover className="relative bg-white">
            <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                <div>
                    <Link className="flex" href="/">
                        <span className="sr-only">OpenTopo</span>
                        <svg
                            className="fill-primary-500"
                            fill="red"
                            height="60"
                            viewBox="0 0 71 69"
                            width="auto"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M69.6 48.3L40.6 13.7V8.79999H51.9V0.299988H37.7V13.8L28.3 25.2L23.7 20.4L0.900001 48.3C-0.199999 49.6 1.3113e-06 51.6 1.4 52.7C2 53.2 2.7 53.4 3.4 53.4C4.3 53.4 5.2 53 5.8 52.2L24.2 29.7L28.8 34.5L39.3 21.7L64.9 52.2C66 53.5 68 53.7 69.3 52.6C70.6 51.6 70.7 49.6 69.6 48.3ZM40.6 3.09999H49.1V5.89999H40.6V3.09999Z" />
                            <path d="M35.1 38.3L24.4 49.1C23.9 49.6 24 50.3 24.4 50.8C24.9 51.3 25.6 51.2 26.1 50.8L33.9 42.9V67.5C33.9 68.2 34.4 68.7 35.1 68.7C35.8 68.7 36.3 68.2 36.3 67.5V42.8L44.1 50.7C44.3 50.9 44.6 51.1 44.9 51.1C45.2 51.1 45.5 51 45.7 50.8C46.2 50.3 46.2 49.6 45.7 49.1L35.1 38.3Z" />
                        </svg>
                    </Link>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                        <span className="sr-only">Otevřít menu</span>
                        <Bars3Icon aria-hidden="true" className="size-6" />
                    </Popover.Button>
                </div>
                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                    <nav className="flex space-x-10">
                        {menuItems.map((m) => (
                            <Link
                                className="text-base font-medium text-gray-500 hover:text-gray-900"
                                href={m.href}
                                key={m.name}
                            >
                                {m.name}
                            </Link>
                        ))}
                    </nav>
                    <div className="flex items-center md:ml-12">
                        <a
                            className="text-base font-medium text-primary-500 hover:text-gray-900"
                            href={loggedProfile ? "/api/auth/logout" : "/api/auth/login"}
                            title={loggedProfile ? loggedProfile.email : undefined}
                        >
                            {loggedProfile ? "Odhlásit se" : "Přihlásit se"}
                        </a>
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    className="absolute top-0 inset-x-0 p-2 transition origin-top-right md:hidden z-10"
                    focus
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <svg
                                        className="fill-primary-500"
                                        fill="red"
                                        height="50"
                                        viewBox="0 0 71 69"
                                        width="auto"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M69.6 48.3L40.6 13.7V8.79999H51.9V0.299988H37.7V13.8L28.3 25.2L23.7 20.4L0.900001 48.3C-0.199999 49.6 1.3113e-06 51.6 1.4 52.7C2 53.2 2.7 53.4 3.4 53.4C4.3 53.4 5.2 53 5.8 52.2L24.2 29.7L28.8 34.5L39.3 21.7L64.9 52.2C66 53.5 68 53.7 69.3 52.6C70.6 51.6 70.7 49.6 69.6 48.3ZM40.6 3.09999H49.1V5.89999H40.6V3.09999Z" />
                                        <path d="M35.1 38.3L24.4 49.1C23.9 49.6 24 50.3 24.4 50.8C24.9 51.3 25.6 51.2 26.1 50.8L33.9 42.9V67.5C33.9 68.2 34.4 68.7 35.1 68.7C35.8 68.7 36.3 68.2 36.3 67.5V42.8L44.1 50.7C44.3 50.9 44.6 51.1 44.9 51.1C45.2 51.1 45.5 51 45.7 50.8C46.2 50.3 46.2 49.6 45.7 49.1L35.1 38.3Z" />
                                    </svg>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                        <span className="sr-only">Zavřít menu</span>
                                        <XMarkIcon aria-hidden="true" className="size-6" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-6">
                                    {menuItems.map((item) => (
                                        <Link href={item.href} key={item.name}>
                                            <a
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                                key={item.name}
                                            >
                                                <div className="text-base font-medium text-gray-900">{item.name}</div>
                                            </a>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5">
                            <a
                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700"
                                href={loggedProfile ? "/api/auth/logout" : "/api/auth/login"}
                                title={loggedProfile ? loggedProfile.email : undefined}
                            >
                                {loggedProfile ? "Odhlásit se" : "Přihlásit se"}
                            </a>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
